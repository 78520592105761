 

const Footer = (props) => {
     
 
     return (
        <div className="footer2 w-container">
            <div className="div-block-3">
                <div className="text-block-14">©2022 by 576 Labs</div>
            </div>
            <div className="div-block-5 w-clearfix">
                <img src="/images/Discord-Logo-White.png" loading="lazy" width="25" alt="" className="image-9"/>
                <img src="/images/Logomark-Transparent-White.png" loading="lazy" width="25" height="25" alt="" className="image-10"/>
                <img src="/images/twitter-logo-white-white-twitter-logo-symbol-animal-label-transparent-png-1197115.png" loading="lazy" width="25" height="25" alt="" className="image-11"/>
                <a href="#" className="button-5 w-button">terms &amp; conditions</a>
            </div>
        </div>
    );
    
    
};

export default Footer;
