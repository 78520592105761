/* eslint-disable */
import { useEffect, useState } from "react"; 
import {
  connectWallet,
  getCurrentWalletConnected,
  mintNFT, 
  premintNFT,
  getOwnedTokens,
} from "../util/interact.js";

// ES6 Modules or TypeScript
import Swal from 'sweetalert2'


var url = require('url');

var getUrl = (window.location).toString();
//console.log(getUrl);
var getAddress = url.parse(getUrl, true);
var getPathname = getAddress.pathname;

const Minter = (props) => {
    const [walletAddress, setWallet] = useState("");
    const [status, setStatus] = useState("");

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [url, setURL] = useState("");

    useEffect(async () => {
        const { address, status } = await getCurrentWalletConnected();

        setWallet(address);

        if (status == "connected") {
            setStatus(<p>
                {" "}
                🦊{" "}

                <button id="mintButton" onClick={onMintPressed}>
                    Mint NFT
                         </button>
            </p>
            );
        } else {
            setStatus(status);

        }

        addWalletListener();
    }, []);

    function addWalletListener() {
        if (window.ethereum) {
            window.ethereum.on("accountsChanged", (accounts) => {
                if (accounts.length > 0) {
                    setWallet(accounts[0]);
                    // setStatus("Write a message in the text-field above.");
                    setStatus(
                        <p>
                            {" "}
                            🦊{" "}
                            <button id="mintButton" onClick={onMintPressed}>
                                Mint NFT
                       </button>
                        </p>
                    );
                } else {
                    setWallet("");
                    setStatus("Connect to Metamask using the top right button.");
                }
            });
        } else {
            setStatus(
                <p>
                    {" "}
                    🦊{" "}
                    <a target="_blank" href={`https://metamask.io/download.html`}>
                        You must install Metamask, a virtual Ethereum wallet, in your
            browser.
          </a>
                </p>
            );
        }
    }

    const gobackTravelToucans = async () => {

        document.location = "https://traveltoucans.com";
    }

    const connectWalletPressed = async () => {

        const walletResponse = await connectWallet();

        if (walletResponse.status == "connected") {
            setStatus(<p>
                {" "}
                🦊{" "}
                <button id="mintButton" onClick={onMintPressed}>
                    Mint NFT
            </button>
            </p>
            );
        } else {
            setStatus(walletResponse.status);

        }

        // setStatus(walletResponse.status);
        setWallet(walletResponse.address);
    };

    const onMintPressed = async () => {
       // console.log("pre mint nft");
        const { success, status } = await mintNFT(url, name, description);
        // setStatus(status);
        //console.log(success);
        //console.log("call swal");

        if (!success) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: status
            })
        } else {
            Swal.fire({
                icon: 'success',
                title: 'Success!',
                text: status
            })

        }
    setStatus(<p>
        {" "} 
        <section id="slideMint" > 
            <div className="row rowMint"> 
                <div className="Minter">
                    <button id="mintButton" onClick={onMintPressed}>
                    Mint NFT
                    </button>
                </div>
            </div> 
        </section>
    </p>
    );
    //if (success) {
    //  setName("");
    //  setDescription("");
    //  setURL("");
   // }
  };
 
    
    
};

export default Minter;
