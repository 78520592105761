/* eslint-disable */
import { useEffect, useState } from "react";
import {
  connectWallet,
  getCurrentWalletConnected,
  mintNFT, 
  premintNFT,
} from "../util/interact.js";

// ES6 Modules or TypeScript
import Swal from 'sweetalert2'


var url = require('url');

var getUrl = (window.location).toString();
//console.log(getUrl);
var getAddress = url.parse(getUrl, true);
var getPathname = getAddress.pathname;

const Menu = (props) => {
    const [walletAddress, setWallet] = useState("");
    const [status, setStatus] = useState("");

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [url, setURL] = useState("");

    useEffect(async () => {
         
    }, []);

   

    const onSubscribePressed = async () => {
       // console.log("pre mint nft");

       
  const axios = require('axios');
       const emailAddress = document.getElementById("email").value;

       let res = await axios.post('http://localhost:5000/api/subscribe', {
        email: emailAddress
        })
    
        if (res.data.success == false) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: "You must provide a valid email."
            })
        } else {
            Swal.fire({
                icon: 'success',
                title: 'Success!',
                text: "Thanks for signing up. We'll be in touch!"
            })

        }
      
  };

 
            return (
                <div id="w-node-b5dd2a11-794f-331a-5efe-cb02c04b8935-1377eb02" className="w-form">
                         <input type="email"    className="text-field-2 w-input" maxlength="256" name="email" data-name="Email" placeholder="Enter your email" id="email" required=""/>
                         <button id="mintButton" className="submit-button w-button" onClick={onSubscribePressed}  data-wait="Please wait..." >
                        GET NOTIFIED
                            </button> 
                            <form id="email-form" name="email-form" data-name="Email Form" method="get">
                   </form>
                    <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                </div>
            );
    
    
};

export default Menu;
