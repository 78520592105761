/* eslint-disable */
import { useEffect, useState } from "react";
import ReactHtmlParser from 'react-html-parser'
import {
  connectWallet,
  getCurrentWalletConnected,
  mintNFT, 
  premintNFT,
  getOwnedTokens 
} from "../util/interact.js";

// ES6 Modules or TypeScript
import Swal from 'sweetalert2'
var htmlString2 = "";
export  const onGalleryPressed = async (id) => {
    console.log("pre mint nft" + id);
 }

const Gallery = (props) => {

    const [walletAddress, setWallet] = useState("");
    const [status, setStatus] = useState(""); 
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [url, setURL] = useState("");
    const [htmlString, setHtml] = useState(null);
    const [msgString, setMsgHtml] = useState(null);
    
  
    function showSpinner() {
        document.getElementById('loader').style.display = 'block';
    }
    
    function hideSpinner() {
        document.getElementById('loader').style.display = 'none';
    }
    
    useEffect(async () => {

        const { address, status } = await getCurrentWalletConnected();
 
        if (status == "connected") {

            try {
                showSpinner()

                var   array   = await getOwnedTokens();

                const ownArray = array;
    
                console.log(ownArray.array.length);
                
                if (ownArray.array.length == 0 ){
    
                    Swal.fire({
                        icon: 'info',
                        title: 'No Foxes detected...',
                        text: "No foxies found. Join the fam first!"
                    })
                    
                setMsgHtml("You do not hold a Pixes Fox from the genesis collection. Buy a fox through your favorite marketplace! ");
                    return;
                }
              
    
                for (var i = 0; i < ownArray.array.length; i++) {
                    console.log(ownArray.array[i]);
                    //Do something
                    var imgSrc= "https://tokentoucan.com/pfnfts/" + ownArray.array[i] + ".png"
                    htmlString2 = htmlString2 + '<a href="javascript:myFunc('+ownArray.array[i] +');function myFunc(id){document.getElementById(\'my-fox-id\').innerHTML = \'Pixel Fox #'+ownArray.array[i] +'<br/><img src=' + imgSrc + '  />\'}"    class="gallery-lightbox w-lightbox"> <img src="https://tokentoucan.com/pfnfts/' + ownArray.array[i] + '.png" alt="" class="gallery-thumbnail"/></a>'
                }
                setMsgHtml("Click on one of your Foxes below to show more details.");
                setHtml(htmlString2);

              } catch (err) { 
              } finally {
                hideSpinner()
              }

           
        } 
 
    }, []);

  
    
    return ( 
        
        <div class="w-layout-grid gridgallery">
            
            <div id="w-node-_3f4b31e4-55f4-468d-26e9-211db21ee2b8-bd2680dd" class="div-block-gallery-right">
                <div class="div-block-16">
                    <div class="text-block-18" id="my-fox-id">Click on your Fox</div>
                </div>
                <div class="div-block-16">
                    <div id="w-node-b5dd2a11-794f-331a-5efe-cb02c04b8935-bd2680dd" class="w-form">
                        <form id="email-form" name="email-form" data-name="Email Form" method="get" class="form">
                            <div  >

                            <input type="submit" value="$ coming soon $" data-wait="Please wait..." class="submit-button w-button"/>
                          
                            </div> 
                        </form>
                        <div class="w-form-done">
                            <div>Thank you! Your submission has been received!</div>
                        </div>
                        <div class="w-form-fail">
                            <div>Oops! Something went wrong while submitting the form.</div>
                        </div>
                    </div>
                </div> 
            </div>
            <div id="w-node-_292bd866-9604-0df6-5838-9a7e64b4c219-bd2680dd" class="div-block-17"> 
                <div id="loader" >Mom! Meatloaf!</div>
                <div id="msg" >{ReactHtmlParser(msgString)}</div>
               
                <div class="gallery-grid-container">
                     {ReactHtmlParser(htmlString)}
                </div>
            </div>
         </div>
             
        );
         

    };

export default Gallery;
