/* eslint-disable */
import { useEffect, useState } from "react";
import {
    connectWallet,
    getCurrentWalletConnected,
    mintPixelFoxesNFT,  
    getOwnedTokensOFCollection,
    getOwnedTokensSSCollection,
} from "../util/interact_Spooky.js";
import Swal from 'sweetalert2'

import ReactBootstrapSlider from 'react-bootstrap-slider';
import "../css/bootstrap.css";
import "../css/bootstrap-slider.css"
 
const SliderDiv = (props) => {
    

    return (
        <div>
            
            <input id="val" type="text"></input>
        </div>
        
        );
};

const MinterSpooky = (props) => {
    
    const [walletAddress, setWallet] = useState("");
    const [status, setStatus] = useState("");
    const [mintBtn, setMintBtn] = useState("");

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [url, setURL] = useState("");
    const [mintNumber, setMintNumber] = useState("");

    useEffect(async () => {
        const { address, status } = await getCurrentWalletConnected();

        setWallet(address);

        if (status == "connected") {
             
            var balSS = await getOwnedTokensSSCollection();

            if (parseInt(balSS) > 1) {
                    
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'You have minted the maximum amount of Spooky Foxes for this wallet. Please move to another wallet to mint more.'
                    }) 
            }
            
                
            setStatus(<p>
                {" "}  
                <p id="status-info">Your free spooky reward awaits above. You can only mint a maximum of 2 Spooky Foxes per wallet and only 1 per transaction.
                </p> 
            </p>
            );
            setMintBtn(<p> 
                <button id="mintButton" className="button-7 w-button" onClick={onMintPressed}>
                Mint for free
                </button> 
                <div class="text-block-19">if you dare</div>  
            </p>
            );

             
            
        } else {
           // setStatus(status);
            //setWallet("");

            setStatus(<p>
                {" "}  
                <p id="status-info">Connect to Metamask using the top right button.
                </p> 
            </p> );
             setMintBtn(<p> 
                <button id="mintButton" className="button-7 w-button" onClick={onNotConnected}>
                Mint for free
                </button> 
                <div class="text-block-19">if you dare</div>  
            </p>
            );

        }

        addWalletListener();
    }, []);

    function addWalletListener() {
        if (window.ethereum) {
            window.ethereum.on("accountsChanged", (accounts)  => {
                 
                addWalletListener2(accounts);
               
            });
        } else {
            setStatus(
                <p>
                    {" "}
                    🦊{" "}
                    <a target="_blank" href={`https://metamask.io/download.html`}>
                        You must install Metamask, a virtual Ethereum wallet, in your
                        browser.
          </a>
                </p>
            );
        }
    }


    const addWalletListener2 = async (accounts) => {

        if (accounts.length > 0) {
            setWallet(accounts[0]);

            var balSS =  await getOwnedTokensSSCollection();

            if (parseInt(balSS) > 1) {
                    
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You have minted the maximum amount of Spooky Foxes for this wallet. Please move to another wallet to mint more.'
                }) 
            }
            
                
            setStatus(<p>
                {" "}  
                <p id="status-info">Your free spooky reward awaits above. You can only mint a maximum of 2 Spooky Foxes per wallet and only 1 per transaction.
                </p> 
            </p>
            );
            setMintBtn(<p> 
                <button id="mintButton" className="button-7 w-button" onClick={onMintPressed}>
                Mint for free
                </button> 
                <div class="text-block-19">if you dare</div>  
            </p>
            );


        } else {
            setWallet("");

            setStatus(<p>
                {" "}  
                <p id="status-info">Connect to Metamask using the top right button.
                </p> 
            </p> );
             setMintBtn(<p> 
                <button id="mintButton" className="button-7 w-button" onClick={onMintPressed}>
                Mint for free
                </button> 
                <div class="text-block-19">if you dare</div>  
            </p>
            );
        }

    }


    function  changeValue(obj) {
         document.getElementById("txtMintNumber").value = obj.target.value;
    }
    const gobackPixelFoxes = async () => {

        document.location = "https://pixelfoxesnft.com";
    }

    const connectWalletPressed = async () => {

        const walletResponse = await connectWallet();

        if (walletResponse.status == "connected") {

            
            var balSS = await getOwnedTokensSSCollection();

            if (parseInt(balSS) > 1) {
                    
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You have minted the maximum amount of Spooky Foxes for this wallet. Please move to another wallet to mint more.'
                }) 
            }
            
                
            setStatus(<p>
                {" "}  
                <p id="status-info">Your free spooky reward awaits above. You can only mint a maximum of 2 Spooky Foxes per wallet and only 1 per transaction.
                </p> 
            </p>
            );
            setMintBtn(<p> 
                <button id="mintButton" className="button-7 w-button" onClick={onMintPressed}>
                Mint for free
                </button> 
                <div class="text-block-19">if you dare</div> 
            </p>
            );

 
        } else {
            setStatus(walletResponse.status);

        }

        // setStatus(walletResponse.status);
        setWallet(walletResponse.address);



    };

    
      // console.log("pre mint nft");
      const onNotConnected = async () => {
   
     
          Swal.fire({
              icon: 'info',
              title: 'Connect a Wallet',
              text: 'You must connect your wallet in order to mint. '
          })
          return;
      
    }

    const onMintPressed = async () => {
        // console.log("pre mint nft");

        var balSS = await getOwnedTokensSSCollection();

        if (parseInt(balSS) > 1) {

            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You have minted the maximum amount of Spooky Foxes for this wallet. Please move to another wallet to mint more.'
            })
            return;
        }



        const { success, status } = await mintPixelFoxesNFT(url, name, description);
        // setStatus(status);
        //console.log(success);
        //console.log("call swal");

        if (!success) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: status
            })
        } else {
            Swal.fire({
                icon: 'success',
                title: 'Success!',
                text: status
            })

        } 

        var balSS = await getOwnedTokensSSCollection();

        if (parseInt(balSS) > 1) {
                    
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You have minted the maximum amount of Spooky Foxes for this wallet. Please move to another wallet to mint more.'
            }) 
        }
        
            
        setStatus(<p>
            {" "}  
            <p id="status-info">Your free spooky reward awaits above. You can only mint a maximum of 2 Spooky Foxes per wallet and only 1 per transaction.
            </p> 
        </p>
        );
        setMintBtn(<p> 
            <button id="mintButton" className="button-7 w-button" onClick={onMintPressed}>
            Mint for free
            </button> 
            <div class="text-block-19">if you dare</div>
        </p>
        );
         

    };
    //color scheme: #aaf8fe
    return (
       <div>
        <div className="div-block-7">
            <img src="images/712x.png" loading="lazy" width="48" height="48" alt="" className="image-13"/> 
            <div className="div-block-6"> 
                {mintBtn} 
                <form>  </form> 
            </div>
            <img src="images/462x.png" loading="lazy" width="48" height="48" alt="" className="image-14"/>
            
        </div>         
            <p id="status" style={{ width: '100%'}}>
                {status}
            </p>  
        </div>         
    );
};

export default MinterSpooky;
