/* eslint-disable */
import React from 'react';
//import './App.css';
import Minter from './projects/Minter'  
import MinterSpooky from './projects/MinterSpooky'
import Menu from './projects/Menu'  
import Gallery from './projects/Gallery'
import Footer from './projects/Footer'
import Subscribe from './projects/Subscribe'


//import Pixelfoxes from './projects/Pixelfoxes'
  

import './css/normalize.css';
import './css/webflow.css';
import './css/pixel-foxes.webflow.css'; 
 
var url = require('url');

var getUrl = (window.location).toString();
//console.log(getUrl);
var getAddress = url.parse(getUrl, true);
var getPathname = getAddress.pathname;
//console.log(getPathname);
 
  
class App extends React.Component {
 
    render() {
        const handleOxfordcircle = () => {
            const favicon = document.getElementById("favicon"); 
            favicon.href = "./favicon_pa.ico";
        };
         
        
         
        switch (getPathname) {
             
            case "/Spookys":
            case "/spookys":
                return (
                    <div className="App">
                        <div>
                            <h1>NFT Launchpad</h1>
                            <p>powered by doq</p>
                        </div>
                    </div>
                );
                break;
            case "/SpookyMint":
            case "/spookymint":
                return (
                    <div className="App">
                        <div>
                            <h1>NFT Launchpad</h1>
                            <p>powered by doq</p>
                        </div>
                    </div>
                );
                break;
            case "/home":
                return (
                    <div>
                    <Menu></Menu>
                    <div className="section cc-store-home-wrap">
                        <Minter></Minter>
                    </div>
                    </div>
                );
                break; 
            case "/FoxDen":
            case "/foxden":
                        return (
                            <div id="foxDen">
                                <Menu></Menu>
                                <div className="section cc-store-home-wrap">
                                    <div data-v-app="" id="app">
                                        <div className="section">
                                            <div> 
                                            </div>
                                            <div className="intro-header cc-subpage">
                                            <h1 className="heading-3">The <span>Fox</span> den</h1> 
                                            <button  class="submit-button w-button" data-wait="Coming Soon">Coming Soon</button>
                                            <div className="intro-content"></div>
                                            </div>
                                        </div>
                                        
                                        <Footer></Footer>
                                    </div> 
                                </div>
                            </div> 
                        );
                        break;
                        case "/Foxels":
                    case "/foxels":
                        return (
                            <div>
                                <Menu></Menu>
                                <div id="Foxels" className="section cc-store-home-wrap">
                                    <div data-v-app="" id="app">
                                        <div className="section-2 wf-section">
                                            <div>
                                            <h1 className="heading-3">foxels</h1>
                                            <h1 className="heading-4">by pixel foxes</h1>
                                            </div>
                                            <div className="w-layout-grid ">
                                                <img src="images/PF-sidebyside2x.png" loading="lazy" id="w-node-cc450283-91a9-c4df-f6fa-279fdd09fad1-1377eb02" 
                                                sizes="(max-width: 767px) 100vw, 500px" 
                                                srcset="images/PF-sidebyside2x-p-500.png 500w, images/PF-sidebyside2x-p-800.png 800w, images/PF-sidebyside2x-p-1080.png 1080w, images/PF-sidebyside2x.png 1249w" 
                                                alt="" className="image-25"/>
                                                <div id="w-node-_3f4b31e4-55f4-468d-26e9-211db21ee2b8-1377eb02" className="div-block-11">
                                                    <div className="text-block-15">launching soon!</div>
                                                    <div className="text-block-18">20 TOTAL SUPPLY</div>
                                                    <div className="text-block-16">10k pixel fox reward</div>
                                                    <div className="text-block-17">10k open mint</div>
                                                    <Subscribe></Subscribe>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <Footer></Footer>
                                    </div> 
                                </div>
                            </div> 
                        );
                        break;
                        case "/Spooky":
                        case "/spooky":
                        return (
                            <div>
                                <Menu></Menu>
                                <div id="Spooky" className="section cc-store-home-wrap">
                                    <div data-v-app="" id="app">
                                            <div className="section cc-home-wrap">
                                                <div className="w-layout-grid grid">
                                                <div id="w-node-_80128f87-3153-37b0-37ad-37aba920bfb6-e677eb0a"></div>
                                                <div id="w-node-ef78f63e-6150-62ad-e3f0-68d8abb73519-e677eb0a" className="div-block-8">
                                                    <h1 className="heading-5">Pixel Foxes </h1>
                                                    <div className="text-block-20">Spooky Season</div>
                                                    <div className="text-block-21">2D Collection</div>
                                                     
                                                       <MinterSpooky></MinterSpooky> 
                                                    
                                                       <div className="div-block-9">
                                                        <img src="images/Logomark-Transparent-White2x.png" loading="lazy" width="48" height="48" alt="" className="image-15"/>
                                                        <div className="text-block-22"> 
                                                            <a target="_blank" href="https://opensea.io/collection/pixelfoxes">See the Full Collection</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                            
                                            <Footer></Footer>
                                    </div> 
                                </div>
                            </div> 
                        );
                        break;
                        case "/Merch":
                        case "/merch":
                        return (
                            <div>
                                <Menu></Menu>
                                <div id="Merch" className="section cc-store-home-wrap"> 
                                    <div data-v-app="" id="app">
                                        <div className="section">
                                            <div className="w-layout-grid grid-2 grid-2-merch" >
                                            <div id="w-node-_82f77095-1603-0551-c1f7-5935c01253ba-8577eb03" className="div-block-12 w-clearfix">
                                                <img src="images/Foxels_Test_12_20_6.png" loading="lazy" sizes="(max-width: 991px) 100vw, 562px" srcset="images/Foxels_Test_12_20_6-p-500.png 500w, images/Foxels_Test_12_20_6-p-800.png 800w, images/Foxels_Test_12_20_6.png 1014w" alt="" className="image-16"/>
                                            </div>
                                            <div id="w-node-_5d328d10-8265-ec11-779c-48d4c1b4ddf1-8577eb03" className="div-block-13">
                                                <img src="images/3D_glasses2x.png" loading="lazy" width="200" id="w-node-_40a36fa7-35c8-7238-b6d4-82b3a65445c9-8577eb03" 
                                                srcset="images/3D_glasses2x-p-500.png 500w, images/3D_glasses2x.png 716w" 
                                                sizes="200px" alt="" className="image-17"/>
                                                <div className="text-block-23">Looking for</div>
                                                <h1 className="heading-6">merch?</h1>
                                                <div className="text-block-24">The first physical merch drop for Pixel Fox and Foxel holders is on it&#x27;s way!</div>
                                                <Subscribe></Subscribe>
                                                <div className="w-clearfix merch-img-div">
                                                    <img src="images/Beanie2x.png" loading="lazy" width="200" sizes="200px" srcset="images/Beanie2x-p-500.png 500w, images/Beanie2x.png 729w" alt="" className="image-18"/>
                                                    <img src="images/Ball_cap2x.png" loading="lazy" width="200" sizes="200px" srcset="images/Ball_cap2x-p-500.png 500w, images/Ball_cap2x.png 759w" alt="" className="image-19"/>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        
                                        <Footer></Footer>
                                    </div> 
                                </div>
                            </div> 
                        );
                        break;
                        case "/Roadmap":
                            case "/roadmap":
                            return (
                                <div>
                                    <Menu></Menu>
                                    <div id="Roadmap" className="section cc-store-home-wrap"> 
                                        <div data-v-app="" id="app">
                                            
                                            <div className="section">
                                                <div className="div-block-15"></div>
                                                <div className="div-block-14">
                                                <div className="text-block-25">pixel foxes</div>
                                                <h1 className="heading-7">Roadmap</h1>
                                                </div>
                                                <div className="w-layout-grid grid-3">
                                                <div id="w-node-_6fa4b9cd-7fda-9799-5e06-7872af6289ce-1077eb09" className="w-clearfix">
                                                    <img src="images/PF-base-orange2x.png" loading="lazy" width="120" sizes="(max-width: 479px) 69vw, 120px" srcset="images/PF-base-orange2x-p-500.png 500w, images/PF-base-orange2x.png 548w" alt="" className="image-21"/>
                                                </div>
                                                <div id="w-node-_81bcca5d-117e-1aee-8309-8e5687913876-1077eb09">
                                                    <div className="text-block-26">stage one</div>
                                                    <div className="text-block-27">pixel foxes launch</div>
                                                    <div className="text-block-28">Pixel Foxes Official NFTs dropped an origin run of 10,000 NFTs, which sold out in 30 hours.</div>
                                                </div>
                                                <div id="w-node-_18e33de8-4391-e183-0063-af201d3a3115-1077eb09"></div>
                                                <div id="w-node-_0d3b62e6-01b3-bc78-90b5-f8bb6b4388ca-1077eb09"></div>
                                                <div id="w-node-_5740e652-00d0-767c-4d18-b1532a3ac655-1077eb09">
                                                    <div id="w-node-_87823c77-d5ea-a8e0-04e7-5d04bf0e6722-1077eb09" className="text-block-36">Stage two</div>
                                                    <div className="text-block-37">3d development</div>
                                                    <div className="text-block-38">Development of 3D Foxels and The Fox Den begins.</div>
                                                </div>
                                                <div id="w-node-_8069c23e-c2d3-4af6-4ac3-52a7b1c0c247-1077eb09">
                                                    <img src="images/Sketch_1.png" loading="lazy" width="220" alt="" className="image-22"/>
                                                </div>
                                                <div id="w-node-_79c8c00b-f683-6aaf-10f4-a594e3f4e687-1077eb09" className="w-clearfix">
                                                    <img src="images/Foxels_Test_12_20_32x.png" loading="lazy" width="220" sizes="(max-width: 479px) 88vw, 220px" srcset="images/Foxels_Test_12_20_32x-p-500.png 500w, images/Foxels_Test_12_20_32x-p-800.png 800w, images/Foxels_Test_12_20_32x.png 878w" alt="" className="image-23"/>
                                                </div>
                                                <div id="w-node-_266f4f8e-7167-efa8-1bbc-ef5a82d7c02f-1077eb09">
                                                    <div className="text-block-34">Stage three</div>
                                                    <div className="text-block-33">foxels launch</div>
                                                    <div className="text-block-35">Foxels launch 20,000 NFTs on the Ethereum blockchain, with 10K Foxels allocated to reward Pixel Fox holders and 10K for public mint.</div>
                                                </div>
                                                <div id="w-node-_74ac5347-897b-b105-ec80-270f1d9cf102-1077eb09"></div>
                                                <div id="w-node-cebff56a-30c3-37f2-9e17-b92805c30bc9-1077eb09"></div>
                                                <div id="w-node-_5b6ca687-2f43-e812-5a76-85578af34e92-1077eb09">
                                                    <div className="text-block-32">Stage Four</div>
                                                    <div className="text-block-31"> The Fox Den Launches</div>
                                                    <div className="text-block-40">The Fox Den opens, allowing Foxel owners to meet and chat in a 3D virtual environment. Additional development will open portals to new worlds where holders earn rewards through play-to-earn gamification.</div>
                                                </div>
                                                <div id="w-node-c104e2b3-61eb-3f32-dcf0-eb4f02605551-1077eb09">
                                                    <img src="images/Portal_12x.png" loading="lazy" width="220" sizes="(max-width: 479px) 88vw, 220px" srcset="images/Portal_12x-p-500.png 500w, images/Portal_12x-p-800.png 800w, images/Portal_12x.png 874w" alt="" className="image-24"/>
                                                </div>
                                                </div>
                                            </div> 
                                            
                                            <Footer></Footer>
                                        </div> 
                                    </div>
                                </div> 
                            );
                            break;
                            case "/Gallery":
                             case "/gallery":
                                   
                                return (
                                    <div>
                                        <Menu></Menu>
                                        <div className="section cc-store-home-wrap"> 
                                            <div data-v-app="" id="app"> 
                                                <div class="section-gallery wf-section">
                                                    <div>
                                                        {/*<h1 class="heading-3">My Foxes</h1>*/}
                                                        <h1 class="heading-gallery-1">My gallery</h1>
                                                    </div>
                                                    <Gallery></Gallery>
                                                    
                                                </div> 
                                                <Footer></Footer>
                                            </div> 
                                        </div>
                                    </div> 
                                    
                                );
                                break;
                        case "/homeiste":
                            return (
                                <div>
                                    <Menu></Menu>
                                    <div id="Home" className="section cc-store-home-wrap"> 
                                        <div data-v-app="" id="app">
                                            <div className="intro-header">
                                                <div className="intro-content cc-homepage">
                                                    <div className="intro-text">
                                                    <div className="heading-jumbo-1">Welcome to<br/></div>
                                                    <div className="text-block">pixel foxes</div>
                                                    <div className="text-block-4">learn about</div>
                                                    <a href="/foxels" className="button cc-jumbo-button cc-jumbo-white w-inline-block">
                                                    <div className="text-block-3">foxels</div>
                                                    </a>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            <div className="container-2 w-container">
                                                <img src="../images/PF-row-upper-2b2x.png" loading="lazy" sizes="(max-width: 3844px) 100vw, 3844px" srcset="images/PF-row-upper-2b2x-p-500.png 500w, images/PF-row-upper-2b2x-p-800.png 800w, images/PF-row-upper-2b2x-p-1080.png 1080w, images/PF-row-upper-2b2x-p-1600.png 1600w, images/PF-row-upper-2b2x-p-2000.png 2000w, images/PF-row-upper-2b2x-p-2600.png 2600w, images/PF-row-upper-2b2x-p-3200.png 3200w, images/PF-row-upper-2b2x.png 3844w" alt="" className="image-12"/>
                                            </div>
                                                <div className="container">
                                                    <div className="motto-wrap">
                                                        <div className="label cc-light">what are</div>
                                                        <div className="text-block-6">pixel foxes?</div>
                                                        <div className="heading-jumbo-small">Pixel Foxes Origin is a collection of hand-drawn and programmatically generated NFTs living awesome fox lives on the Ethereum blockchain. The 1st generation is made up of 10,000 unique foxes, each with a one-of-a-kind combination of breed, clothes, headgear, and more, all drawn in the 24x24 pixel style.
                                                        <br/><br/>​ By collecting a Pixel Fox, you are joining a community centered around art and positive vibes. Come join the conversation in our discord and find your #foreverfox!
                                                        <br/>
                                                        </div>
                                                        <a href="https://opensea.io/collection/pixelfoxes" className="button-2 w-button"></a>
                                                        <div className="text-block-8">
                                                            <a target="_blank" href="https://opensea.io/collection/pixelfoxes">See the Full Collection</a>
                                                        </div>
                                                    </div>
                                                    <div className="container-3 w-container">
                                                        <img src="images/PF-row-upper-2c2x.png" loading="lazy" sizes="(max-width: 3844px) 100vw, 3844px" srcset="images/PF-row-upper-2c2x-p-500.png 500w, images/PF-row-upper-2c2x-p-800.png 800w, images/PF-row-upper-2c2x-p-1080.png 1080w, images/PF-row-upper-2c2x-p-1600.png 1600w, images/PF-row-upper-2c2x-p-2000.png 2000w, images/PF-row-upper-2c2x-p-2600.png 2600w, images/PF-row-upper-2c2x-p-3200.png 3200w, images/PF-row-upper-2c2x.png 3844w" alt=""/>
                                                    </div>
                                                    <div className="divider"></div>
                                                    <div className="home-content-wrap">
                                                        <h1 className="heading">what about</h1>
                                                        <h1 className="heading-2">foxels?</h1>
                                                        <div className="w-layout-grid about-grid"><img src="images/PF-sidebyside2x.png" id="w-node-_86e64837-0616-515b-4568-76c147234d3f-cf77eaf9" sizes="(max-width: 479px) 198.453125px, (max-width: 991px) 330.765625px, 48vw" srcset="images/PF-sidebyside2x-p-500.png 500w, images/PF-sidebyside2x-p-800.png 800w, images/PF-sidebyside2x-p-1080.png 1080w, images/PF-sidebyside2x.png 1249w" alt="" className="image-4"/>
                                                        <div id="w-node-f09a8e6d-47e9-09b2-a7f0-8ec4152f72a4-cf77eaf9" className="text-block-9">Foxels by Pixel Foxes is the newest evolution of the Pixel Fox universe, bringing our 24-pixel characters into a new dimension! As a holder, collectors receive a one-of-a-kind Foxel NFT hosted on the Ethereum blockchain, a 3D model of their Foxel, and the full commercial rights to use their Foxel&#x27;s likeness as they please!
                                                        <br/><br/>We suggest checking out the Fox Den - an interactive 3D world where Pixel Fox and Foxel holders can hang out, host public and private chats, and earn rewards for competing in upcoming games and challenges!
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="container-4 w-container">
                                                    <div className="w-row">
                                                        <div className="column w-col w-col-3">
                                                            <img src="images/Block_7trim.png" loading="lazy" sizes="(max-width: 479px) 78vw, (max-width: 767px) 120px, (max-width: 991px) 23vw, 24vw" srcset="images/Block_7trim-p-500.png 500w, images/Block_7trim.png 742w" alt="" className="image-8"/>
                                                        </div>
                                                        <div className="w-col w-col-3">
                                                            <div className="div-block-2">
                                                            <div className="text-block-10">THE<br/></div>
                                                            <div className="text-block-12">Artist</div><img src="images/Art-Team-Image2x.png" loading="lazy" sizes="(max-width: 479px) 85vw, (max-width: 767px) 230px, (max-width: 991px) 22vw, 21vw" srcset="images/Art-Team-Image2x-p-500.png 500w, images/Art-Team-Image2x.png 560w" alt="" className="image-6"/>
                                                            <div>
                                                                <a href="#" className="button-3 w-button">@perfectihateit</a>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className="column-2 w-col w-col-3">
                                                            <div className="div-block">
                                                            <div className="text-block-11">The <br/></div>
                                                            <div className="text-block-13">dev</div>
                                                                <img src="images/Dev-Team-Image.png" loading="lazy" alt="" className="image-5"/>
                                                            <div>
                                                                <a href="#" className="button-4 w-button">@cypherzilla</a>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className="w-col w-col-3">
                                                            <img src="/images/Block_1trim2x.png" loading="lazy" sizes="(max-width: 479px) 78vw, (max-width: 767px) 120px, (max-width: 991px) 16vw, 17vw" srcset="images/Block_1trim2x-p-500.png 500w, images/Block_1trim2x.png 590w" alt="" className="image-7"/>
                                                        </div>
                                                    </div>
                                                </div>
                                               <Footer></Footer>
                                            </div> 
                                        </div>
                                    </div> 
                                </div>
                            );
                            break;
                    default:
                        return ( 
                            <div id="comingsoon">
                                <div className="gradient-background">
                                    <img src="/images/comingsoon.png" loading="lazy" alt="" className="pf-gradient"/>
                                </div>
                                <div className="wht-pg-border mint">
                                    <div className="content">
                                        <a target="_blank" href="https://opensea.io/collection/pixelfoxes" className="connect-to-mint w-inline-block">
                                        <h1 className="button-text"><img src="/images/comingsoon.svg"/></h1>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        );
                         break;
              }
    }
}

export default App;

